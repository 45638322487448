<template class="bg-white">
  <main class="container-fluid">
      <div class="row row-100-vh">
        <section class="col-12 main-content p-0">
            <b-row>
                <b-col lg="12">
                    <a href="/contraagripe">
                        <img class="d-none d-md-block col-12 p-0" fluid center :src="bannerCampaign" alt="banner-campaign"></img>
                        <b-img-lazy class="d-block d-md-none col-12 p-0" fluid center :src="bannerCampaignMobile" alt="banner-campaign-mobile"></b-img-lazy>
                    </a>
                </b-col>
            </b-row>
            <b-row class="mx-lg-5 ">
                <b-col lg="12" class="pt-5 px-xl-5 px-lg-3 px-md-3 px-3 mx-lg-0">
                    <InitialHeader/>
                </b-col>
            </b-row>
            <b-row class=" m-lg-0">
                <b-col lg="6" md="12" class="p-0">
                    <div class="pl-xl-5 ml-lg-5 px-3">
                        <div class="container-titulo">
                            <h1 class="text-dark text-h1-below">Prevenção que promove o</h1>
                            <h1 class="text-dark text-h1-above">desenvolvimento da Indústria</h1>
                        </div>
                        <div class="content-texts">
                            <div class="texts">
                                <p class="text-dark font-size-text">A vacinação contra a gripe promove melhorias na saúde, bem-estar e produtividade dos seus colaboradores.</p>
                                <p class="text-dark font-size-text mb-2">
                                    Conte com o SESI para apoiar você e a sua empresa.
                                </p>
                            </div>
                            <div class="buttons-container col-xl-7 col-lg-12 p-0">
                                <b-button
                                    :to="{ name: 'budget-contact' }"
                                    id="button-empresas"
                                    class="mr-lg-2 mb-4 rounded-pill w-100"
                                    variant="custom-blue"
                                    size="lg"
                                >Faça seu orçamento</b-button>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col lg="6" md="12" class="p-0">
                    <b-img-lazy class="col-12 p-0" fluid right :src="imgCampaign" alt="img-campaign"></b-img-lazy>
                </b-col>
            </b-row>
            <b-row class="mx-lg-5 mt-3">
                <b-col class="d-none d-lg-block pl-xl-5 pl-lg-4 p-0" lg="4">
                    <b-img-lazy fluid left :src="smallCalendar" alt="small-calendar"></b-img-lazy>
                </b-col>
                <b-col lg="8" xl="7" sm="12">
                    <div class="px-lg-5 mx-2">
                        <div class="container-titulo mt-5 mt-lg-0">
                            <h1 class="text-dark text-h1-bottom">Campanhas estaduais</h1>
                        </div>
                        <div class="content-texts">
                            <div class="texts">
                                <p class="text-dark font-size-text">
                                    Cadastre sua empresa ou acesse sua conta clicando no botão abaixo ou acesse a página “Ver campanhas” para conferir a disponibilidade no seu estado.
                                </p><br>
                            </div>
                            <div class="buttons-container col-xl-7 col-lg-10 col-12 p-0">
                                <b-button
                                    :to="{ name: 'auth-login' }"
                                    id="button-empresas"
                                    class="mr-2 mb-2 rounded-pill col-lg-12 w-100"
                                    variant="custom-blue"
                                    size="lg"
                                >Cadastrar ou acessar minha conta</b-button>
                                <b-button
                                    :to="{ name: 'campaign-calendar' }"
                                    id="button-empresas"
                                    class="mr-1 mb-4 rounded-pill col-lg-12 w-100"
                                    variant="outline-custom-blue"
                                    size="lg"
                                >Ver campanhas do meu estado</b-button>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col class="d-block d-lg-none" lg="5">
                    <b-img-lazy fluid center :src="smallCalendar" alt="small-calendar"></b-img-lazy>
                </b-col>
            </b-row>
        </section>
        <FooterInitial/>
      </div>
  </main>
</template>

<script>
import imgCampaign from '@/assets/custom-images/initial-pages/national-campaign.png';
import bannerCampaign from '@/assets/custom-images/initial-pages/banner-campaign.png';
import bannerCampaignMobile from '@/assets/custom-images/initial-pages/banner-campaign-mobile.png';
import smallCalendar from '@/assets/custom-images/initial-pages/calendario-sm.png';
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue';

import { BButton, BRow, BCol, BImgLazy} from 'bootstrap-vue';
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue';

export default {
    title: 'Inicio',

    components: {
    BButton,
    BRow,
    BCol,
    BImgLazy,
    FooterInitial,
    InitialHeader
},

    mounted() {
        this.$helpers.indexablePage();
    },

    data() {
        return {
            imgCampaign,
            smallCalendar,
            bannerCampaign,
            bannerCampaignMobile,
            blueShadow:{
                'background-image': `url(${require('@/assets/custom-images/initial-pages/blue-shadow.png')})`,
                'background-repeat': 'no-repeat',
                'background-position': 'bottom',
                'background-size': 'contain'
            }
        }
    }
}
</script>

<style scoped>
.menu-item a{
   color: #6E6B7B;
   font-weight: 500;
}
.row-100-vh {
    height: 100vh;
}

.font-size-text {
    font-size: 17px;
    line-height: 23px;
}

.font-size-14 {
    font-size: 14px;
    line-height: 21px;
}

.text-h1-above {
    font-size: 49px;
    font-weight: bold;
}

.text-h1-below {
    font-size: 49px;
    font-weight: 400;
}

.text-h1-bottom {
    font-size: 32px;
    font-weight: 500;
}

.main-content {
    padding: 4rem;
}

.container-titulo {
    margin-top: 4rem;
    margin-bottom: 2.5rem;
}

.footer-content {
    margin-top: 4rem;
}

.realization {
    width: 12rem;
}

.overflow-initial-content {
        overflow-x:hidden
    }

@media only screen and (max-width: 1600px) {
    .overflow-initial-content {
        overflow: auto;
    }
}

@media only screen and (max-width: 750px) {
    .main-image {
        display: none;
    }
    .main-content {
        padding: 2.5rem;
    }
}

@media only screen and (max-width: 1024px) and (max-height: 768px) {
    .main-image {
        display: none;
    }
}

@media only screen and (max-height: 940px) {
    .footer-content {
        margin-top: 2rem;
    }
    .container-titulo {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 870px) {
    .container-fluid {
    overflow-x: hidden;
    }
    .footer-content {
        margin-top: 1rem;
    }
    .container-titulo {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .main-content {
        padding: 2.5rem;
    }

    .text-h1-above {
        font-size: 30px;
        font-weight: bold;
    }

    .text-h1-below {
        font-size: 30px;
        font-weight: 400;
    }
}

</style>
