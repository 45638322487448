import { render, staticRenderFns } from "./Initial.vue?vue&type=template&id=5cf59940&scoped=true&class=bg-white&"
import script from "./Initial.vue?vue&type=script&lang=js&"
export * from "./Initial.vue?vue&type=script&lang=js&"
import style0 from "./Initial.vue?vue&type=style&index=0&id=5cf59940&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf59940",
  null
  
)

export default component.exports